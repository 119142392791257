<template>
    <div class="wrapper">
        <div class="left">
            <div>
                <div class="tab" @click="changeTab('Orderlist')" :class="{ 'active': currentTab === 'Orderlist' }"><i
                        class="el-icon-tickets"></i><span class="title">My Orders</span></div>
                <div class="tab" @click="changeTab('Cart')" :class="{ 'active': currentTab === 'Cart' }"><i
                        class="el-icon-shopping-cart-2"></i><span class="title">Shopping Cart</span></div>
                <div class="tab" :class="{ 'active': currentTab === 2 }"><i class="el-icon-service"></i><span
                        class="title">Customer Services</span></div>
                <div class="tab" @click="changeTab('Insight')" :class="{ 'active': currentTab === 'Insight' }"><i
                        class="el-icon-postcard"></i><span class="title">Order Insight</span></div>
                <div class="tab" @click="changeTab('Profile')" :class="{ 'active': currentTab === 'Profile' }"><i
                        class="el-icon-user"></i><span class="title">My
                        Profile</span></div>
                <div class="tab" @click="open()"><i class="el-icon-turn-off"></i><span class="title">Logout</span></div>
            </div>
        </div>
        <div class="right">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            currentTab: 'Orderlist',
            visible: false,// 是否退出登录
        }
    },
    created() {
        this.changeTab('Orderlist')
    },

    beforeRouteEnter(to, from, next) {
        // 在进入路由前调用，此时组件实例还未被创建
        next(vm => {
            // 通过 `vm` 访问组件实例
            vm.currentTab = "Orderlist";
        });
    },
    methods: {
        ...mapActions(["setUser"]),
        changeTab(tab) {
            this.currentTab = tab;
            this.$router.push({ name: tab });
        },
        logout() {
            this.visible = false;
            // 清空本地登录信息
            localStorage.setItem("user", "");
            // 清空vuex登录信息
            this.setUser("");
            this.notifySucceed(this.$t('prompt.hint3'));
        },
        open() {
            this.$confirm('Want to log out?', '', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.logout()
            }).catch(() => {
            });
        }
    },
}
</script>

<style scoped>
.wrapper {
    width: 1280px;
    margin: 0 auto 64px;
    display: flex;
}

.left {
    width: 190px;
    padding-top: 38px;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.tab {
    padding-left: 12px;
    height: 65px;
    line-height: 65px;
    font-size: 14px;
    color: #959595;
    cursor: pointer;
}

.title {
    margin-left: 10px;
    font-size: 16px;
}

.active {
    font-weight: bold;
    color: #FFF;
    background-color: #CA141D;
}

.right {
    margin-left: 20px;
    margin-top: 40px;
    width: 1070px;
}

::v-deep .el-button.el-button--default.el-button--small {
    font-size: 16px;
}
</style>